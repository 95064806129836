// Metronome by ChangHoon Baek from <a href="https://thenounproject.com/icon/metronome-118052/" target="_blank" title="Metronome Icons">Noun Project</a>
export default function MetronomeIcon() {
  return (
    <svg version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m607.48 82.37c-4.2385-2.38-8.8852-3.5738-13.433-3.5738-9.4215 0-18.518 4.7673-23.504 13.539l-34.022 58.924-23.075-13.32-7.5705 29.028v0.10577l-17.543 67.696-17.982 31.196-93.799 162.36v-329.16h60.76l26.647 134.42 4.1176-7.1473 24.374-93.905 3.7927-14.514-10.169-51.232c-2.4933-12.67-13.539-21.775-26.535-21.775l-220.11-8e-3c-12.995 0-24.154 9.0966-26.647 21.775l-90.12 455.88-19.817 100.62c-1.5186 7.9103 0.54398 16.138 5.6288 22.425 5.1981 6.2785 12.889 9.8598 20.906 9.8598l440.29-8e-3h0.21911c14.96 0 27.078-12.119 27.078-27.078 0-3.0221-0.54398-5.8402-1.4053-8.5451l-18.73-95.219c-0.10576-1.8435-0.54398-3.6794-1.0955-5.4172l-41.917-211.52-41.161 71.148 24.154 121.96-119.58 8e-3 89.031-154.23 41.161-71.27 11.809-20.475 65.421-64.342-21.231-12.24 34.022-58.924c7.4572-12.995 3.0221-29.564-9.9807-37.035zm-285.08 418.51h-140.16l79.391-401.73h60.76z"
        strokeWidth="1.9342"
      />
    </svg>
  )
}
